<template>
    <div id="siteHeatmaps" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="heatmaps"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="troubleshooting_heatmapsSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"/>
                                                            <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3"/>
                                                            <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">{{ $t("troubleshooting_heatmaps") }}</h3>
                                            </div>
                                            <div id="select2OptionHeatmap" style="display: flex; align-items: center;">
                                                <div v-show="displayGatewaysSelector" id="blocSelect2OptionHeatmap" class="kt-portlet__head-toolbar">
                                                    <select id="gatewaysHeatmapSelector" class="form-control kt-select2">
                                                        <option></option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="rowSwitchSpan" class="kt-portlet__body kt-portlet__body--fit" style=" padding-left: 25px; padding-right: 25px; padding-bottom: 25px; ">

                                            <div id="blocSwitchSpan" class="row" style="margin: 10px 0px 15px 0px; pointer-events: auto;">
                                                <div>
                                                    <span class="text-dark font-weight-normal filterPadding">{{ $t("troubleshooting_heatmaps") }}</span>
                                                    <span id="heatmap_heatmapSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                                                        <label>
                                                            <input id="heatmap_heatmapLayerSwitchInput" @change="onSwitchHeatmapLayer" v-model="heatmapLayerDisplayed" type="checkbox" name="" />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div class="mleft15">
                                                    <span class="text-dark font-weight-normal filterPadding">{{ $t("gtw_bleGateways") }}</span>
                                                    <span id="heatmap_gatewaysSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                                                        <label>
                                                            <input id="heatmap_gatewaysLayerSwitchInput" @change="onSwitchGatewaysLayer" v-model="gatewaysLayerDisplayed" type="checkbox" name="" />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div class="mleft15">
                                                    <span class="text-dark font-weight-normal filterPadding">{{ $t("act_autocalibrationtags") }}</span>
                                                    <span id="heatmap_beaconsSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                                                        <label>
                                                            <input id="heatmap_beaconsLayerSwitchInput" @change="onSwitchBeaconsLayer" v-model="autocalibrationTagsLayerDisplayed" type="checkbox" name="" />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div class="mleft15">
                                                    <span class="text-dark font-weight-normal filterPadding">{{ $t("area_indoorAreas") }}</span>
                                                    <span id="heatmap_indoorAreasSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                                                        <label>
                                                            <input id="heatmap_indoorAreasLayerSwitchInput" @change="onSwitchIndoorAreasLayer" v-model="indoorAreasLayerDisplayed" type="checkbox" name="" />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div class="mleft15">
                                                    <span class="text-dark font-weight-normal filterPadding">{{ $t("wall_walls") }}</span>
                                                    <span id="heatmap_wallsSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                                                        <label>
                                                            <input id="heatmap_wallsLayerSwitchInput" @change="onSwitchWallsLayer" v-model="wallsLayerDisplayed" type="checkbox" name="" />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>

                                            <app-locationmap mode="troubleshootingHeatmaps" ref="locationmap"></app-locationmap>

                                            <!--begin::Scale bar-->
                                            <div id="rowHeatmapScaleBar" v-show="heatmapLayerDisplayed">
                                                <div id="blocHeatmapScaleBar">
                                                    <h5 class="font-weight-bold text-dark mt-3 mb-3">{{ $t("common_heatmapscale") }}:</h5>
                                                    <div id="scaleBar" class="legendWrapper"></div>
                                                </div>
                                            </div>
                                            <!--end::Scale bar-->

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import LocationMap from "./../map/locationmap.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            displayGatewaysSelector: false,
            heatmapLayerDisplayed: true,
            gatewaysLayerDisplayed: true,
            autocalibrationTagsLayerDisplayed: true,
            indoorAreasLayerDisplayed: false,
            wallsLayerDisplayed: false,
            // params use because now you can have a query in the URL
            initGatewayId : this.$route.params.gatewayId || null,
            buildingId : this.$route.params.initBuildingId || "",
            floorId : this.$route.params.initFloorId || "",
            selectedGatewayId: null,
        };
    },
    created: function() {
        console.log("Component(heatmaps)::created() - called");
        //Change the floor key in the local storage
        const keyStrorage = this.siteId + "_troubleshootingHeatmaps_lastDisplayedFloorId";
        localStorage.setItem(keyStrorage, this.floorId);

        this.gatewaysLayerDisplayed = this.isGatewaysLayerDisplayed;
        this.heatmapLayerDisplayed = this.isHeatmapsLayerDisplayed;
        this.autocalibrationTagsLayerDisplayed = this.isAutocalibrationTagsLayerDisplayed;
        this.indoorAreasLayerDisplayed = this.isIndoorAreasLayerDisplayed;
        this.wallsLayerDisplayed = this.isWallsLayerDisplayed;
        document.addEventListener("fullscreenchange", () => {
            setTimeout(() => {
                const heatmapIsInFullScreen = $("#map #blocSelect2OptionHeatmap").length ? true : false;
                this.initGatewaysHeatmapSelect2(heatmapIsInFullScreen);
            }, 10);
        });
    },
    mounted: function() {
        console.log("Component(heatmaps)::mounted() - Init metronic layout");
        $("#rowHeatmapScaleBar").hide();
        KTLayout.init();
    },
    destroyed () {
        console.log("Component(heatmaps)::destroyed() - called");
        document.removeEventListener("fullscreenchange");
    },
    beforeRouteLeave(to, from, next) {
        this.resetTroubleshootingHeatmapsState();
        this.resetGatewaysState();
        this.resetAutocalibrationTagsState();
        next();
    },
    watch: {

        isGatewaysLayerDisplayed: function(value) {
            this.gatewaysLayerDisplayed = value;
        },

        isHeatmapsLayerDisplayed: function(value) {
            this.heatmapLayerDisplayed = value;
        },

        isAutocalibrationTagsLayerDisplayed: function(value) {
            this.autocalibrationTagsLayerDisplayed = value;
        },

        isIndoorAreasLayerDisplayed: function(value) {
            this.indoorAreasLayerDisplayed = value;
        },

        isWallsLayerDisplayed: function(value) {
            this.wallsLayerDisplayed = value;
        },

        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteBLEGateways: function() {
            // 1. Build selectbox of gateways

            if (this.siteBLEGateways && this.siteBLEGateways.length > 0){
                this.displayGatewaysSelector = true;
                const heatmapIsInFullScreen = $("#map #blocSelect2OptionHeatmap").length ? true : false;
                this.initGatewaysHeatmapSelect2(heatmapIsInFullScreen);
            } else {
                this.displayGatewaysSelector = false;
                // Display message indicating there is no data for this floor
                $(".noDataForHeatmapDiv").remove();
                $("#map").prepend("<div class='noDataForHeatmapDiv'>"+i18n.t('troubleshooting_noDataForHeatmap')+"</div>");
            }
        },

        siteGatewaysHealth: function() {
            console.log("Component(siteGatewaysHealth)::watch(siteGatewaysHealth) called");
            this.getSiteBLEGateways(this.siteId);
        },

        wallTypes: function() {
            this.getSiteWalls(this.siteId);
        },

        // This is the entry point of the component. When floor id is selected, we start to load other stuff
        getSelectedFloorId() {
            // Each time user select another floor, call API to regenerate the heatmap on dedicated floor

            // If there is a query with the floorId given -> give to the getSelectedfloor this floor to show the right heatmap
            if(this.floorId){
                this.removeAllFloorsToSelectedFloors();
                this.setCurrentSelectedFloorId(this.floorId);
                this.addFloorToSelectedFloors({buildingId : this.buildingId, floorId: this.floorId});
                this.floorId = null;
                this.buildingId = null;
            }
            if (this.getSelectedFloorId) {
                $(".noDataForHeatmapDiv").remove();

                // Load autocalibration tags only the first time
                this.getSiteAutoCalibrationTags(this.siteId);
                // Load indoor areas
                this.getSiteIndoorAreas(this.siteId);
                // Load walls type
                this.getWallTypes();
                
                // Get Gateways Health
                this.getSiteGatewaysHealth(this.siteId);

                // Reset selected gateway when changing the floor
                this.selectedGatewayId = null;
            }

        },

        troubleshootingHeatmapData() {
            if (!this.troubleshootingHeatmapData || this.troubleshootingHeatmapData.length == 0) {
                // Display message indicating there is no data for this floor
                $("#map").prepend("<div class='noDataForHeatmapDiv'>"+i18n.t('troubleshooting_noDataForHeatmap')+"</div>");
                $("#rowHeatmapScaleBar").hide();
            } else {
                $(".noDataForHeatmapDiv").remove();
                $("#rowHeatmapScaleBar").show();
            }
        }

    },
    computed: {
        ...mapGetters(["getSelectedBuildingId","siteAutoCalibrationTags", "wallTypes", "troubleshootingHeatmapData", "getSelectedFloorId", "siteBLEGateways", "isGatewaysLayerDisplayed", "isHeatmapsLayerDisplayed", "isAutocalibrationTagsLayerDisplayed", "isIndoorAreasLayerDisplayed", "isWallsLayerDisplayed", "siteGatewaysHealth"])
    },
    methods: {

        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["addFloorToSelectedFloors","removeAllFloorsToSelectedFloors","resetAutocalibrationTagsState", "getWallTypes", "getSiteIndoorAreas", "getSiteWalls", "getSiteAutoCalibrationTags", "setWallsLayerDisplayed", "setIndoorAreasLayerDisplayed", "setAutocalibrationTagsLayerDisplayed", "setGatewaysLayerDisplayed", "setHeatmapsLayerDisplayed", "getTrilaterationHeatmap", "resetTroubleshootingHeatmapsState", "getSiteBLEGateways", "resetGatewaysState", "setCurrentSelectedFloorId", "getSiteGatewaysHealth"]),

        // Called when user activate or desactive display of heatmap layer using Switch selector
        onSwitchHeatmapLayer() {
            // store value in vuex store
            this.setHeatmapsLayerDisplayed(this.heatmapLayerDisplayed);
        },

        // Called when user activate or desactive display of gateways layer using Switch selector
        onSwitchGatewaysLayer() {
            // store value in vuex store
            this.setGatewaysLayerDisplayed(this.gatewaysLayerDisplayed);
        },

        // Called when user activate or desactive display of beacons layer using Switch selector
        onSwitchBeaconsLayer() {
            // store value in vuex store
            this.setAutocalibrationTagsLayerDisplayed(this.autocalibrationTagsLayerDisplayed);
        },

        // Called when user activate or desactive display of indoor areas layer using Switch selector
        onSwitchIndoorAreasLayer() {
            // store value in vuex store
            this.setIndoorAreasLayerDisplayed(this.indoorAreasLayerDisplayed);
        },

        // Called when user activate or desactive display of walls layer using Switch selector
        onSwitchWallsLayer() {
            // store value in vuex store
            this.setWallsLayerDisplayed(this.wallsLayerDisplayed);
        },

        initGatewaysHeatmapSelect2(heatmapIsInFullScreen) {
            // Init Select2 data of categories
            let dataForSelect2 = [];
            for (let i = 0; i < this.siteBLEGateways.length; i++) {
                if (!this.siteBLEGateways[i].building && !this.siteBLEGateways[i].floor){
                    continue;
                }
                // Keep only gateways that are located on the current selected floor
                if (this.siteBLEGateways[i].floor.id != this.getSelectedFloorId){
                    continue;
                }
                let gatewayName = this.siteBLEGateways[i].name;
                let buildingName = this.siteBLEGateways[i].building.name;
                let floorName = this.siteBLEGateways[i].floor.name;
                dataForSelect2.push({
                    id: this.siteBLEGateways[i].id,
                    text: gatewayName + " (" + buildingName + " / " + floorName + ")"
                });
            }
            // Init categories Select2
            commonVueHelper.destroySelect2($("#gatewaysHeatmapSelector"));
            if (dataForSelect2.length > 0){

                dataForSelect2.unshift({
                    id: "ALL_GATEWAYS_OF_CURRENT_FLOOR",
                    text: i18n.t("common_allOfSelected"),
                });

                this.displayGatewaysSelector = true;

                $("#gatewaysHeatmapSelector")
                    .select2({
                        width: "100%",
                        dropdownParent: heatmapIsInFullScreen ? $('#map') : $('body'),
                        data: dataForSelect2,
                        language: {
                            noResults: function() {
                                return i18n.t("common_noResultsFound");
                            }
                        }
                    })
                    .val("ALL_GATEWAYS_OF_CURRENT_FLOOR")
                    .trigger("change")
                    .on("change", (event) => {
                        if (event.target && event.target.value){
                            console.log("Component(heatmaps): New item selected = "+event.target.value);
                            this.selectedGatewayId = event.target.value == "ALL_GATEWAYS_OF_CURRENT_FLOOR" ? null : event.target.value;
                            this.getTrilaterationHeatmap({
                                siteId: this.siteId,
                                floorId: this.getSelectedFloorId,
                                gatewayId: this.selectedGatewayId
                            });
                        }
                    });
                if (this.initGatewayId) {
                    $("#gatewaysHeatmapSelector").val(this.initGatewayId);
                    this.initGatewayId = null;
                } else if (this.selectedGatewayId) {
                    $("#gatewaysHeatmapSelector").val(this.selectedGatewayId);
                    this.selectedGatewayId = null;
                } else {
                    $("#gatewaysHeatmapSelector").val("ALL_GATEWAYS_OF_CURRENT_FLOOR");
                }
                $("#gatewaysHeatmapSelector").trigger({ type: "change" });

            } else {
                this.displayGatewaysSelector = false;
                // Display message indicating there is no data for this floor
                $(".noDataForHeatmapDiv").remove();
                $("#map").prepend("<div class='noDataForHeatmapDiv'>"+i18n.t('troubleshooting_noDataForHeatmap')+"</div>");
            }

        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.filterPadding {
    padding-top: 4px;
    display: inline-flex;
    padding-right: 10px;
}
.mleft15 {
    margin-left: 15px;
}
</style>
